const sizes = Object.freeze({
  contentWidth: 1724,
  pdpWidth: 1295,
  pdpControlWidth: 340,
  borderRadius: 5,
  blockMargin: 50,

  formMaxWidth: 600,
  inputWidth: 340,

  icon: 32,
  iconSmall: 24,
  iconSpacing: 14,
  aspectRatio: '4/6',

  borderRadiusS: 4,
  borderRadiusM: 8,
  borderRadiusL: 16,

  spacingXxs: 2,
  spacingXs: 4,
  spacingS: 8,
  spacingM: 16,
  spacingL: 24,
  spacingXl: 32,
  spacingXxl: 64,

  button: 40,
  buttonSmall: 32,

  dropdownHeight: 150,
  menuHeight: 72,
  cartBar: 56,
  mobileMenuWidth: 430,

  banner: 30,
})

export default sizes
