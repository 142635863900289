'use client'

import NextLink from 'next/link'
import { useParams } from 'next/navigation'
import { forwardRef } from 'react'

import { useRouteChangeContext } from 'core/context/RouteChangeProvider'

// https://github.com/vercel/next.js/blob/400ccf7b1c802c94127d8d8e0d5e9bdf9aab270c/packages/next/src/client/link.tsx#L169
function isModifiedEvent(event: React.MouseEvent): boolean {
  const eventTarget = event.currentTarget as HTMLAnchorElement | SVGAElement
  const target = eventTarget.getAttribute('target')
  return (
    (target && target !== '_self') ||
    event.metaKey ||
    event.ctrlKey ||
    event.shiftKey ||
    event.altKey || // triggers resource download
    (event.nativeEvent && event.nativeEvent.button === 2)
  )
}

const Link = forwardRef<HTMLAnchorElement, React.ComponentProps<'a'>>(function Link({ href, onClick, ...rest }, ref) {
  const useLink = href && href.startsWith('/')
  const { locale } = useParams() || { locale: 'en' }
  const { onRouteChangeStart } = useRouteChangeContext()
  let canonical = false
  if (!useLink)
    return (
      <a
        href={href}
        onClick={onClick}
        {...rest}
      />
    )

  const cleanedHref = href.replace(/\/[a-zA-Z]{2}\//, '/')
  const link = locale !== 'en' ? `/${locale}${cleanedHref}` : cleanedHref

  if (link === 'en') {
    canonical = true
  }

  return (
    <NextLink
      href={link}
      onClick={(event) => {
        if (!isModifiedEvent(event)) {
          const { pathname, search, hash } = window.location
          const hrefCurrent = `${pathname}${search}${hash}`
          const hrefTarget = href as string
          if (hrefTarget !== hrefCurrent) {
            onRouteChangeStart()
          }
        }
        if (onClick) onClick(event)
      }}
      {...rest}
      prefetch={false}
      rel={canonical ? 'canonical' : ''}
      ref={ref}
    />
  )
})

export default Link
