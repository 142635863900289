import type {
  CartMutation,
  CheckoutMutation,
  PurchaseMutation,
  AnalyticsMutation,
  ImpressionsMutation,
  PageMutation,
  DataLayerPush,
  AddToCartDataLayerPush,
  RemoveFromCartDataLayerPush,
  DetailDataLayerPush,
  CheckoutDataLayerPush,
  PurchaseDataLayerPush,
  AnalyticsDataLayerPush,
  ConsentLayerPush,
  ImpressionsDataLayerPush,
} from 'core/types/tracking'
import { isClient } from 'core/utils/next'
import stringToBoolean from 'core/utils/stringToBoolean'

type PageViewDataLayerPush = {
  event: 'pageView'
} & DetailDataLayerPush

function pushDataLayerEvent(event: DataLayerPush): void {
  const isB2B = stringToBoolean(process.env.NEXT_PUBLIC_B2B)

  if (isClient) {
    window['dataLayer'] = window['dataLayer'] || []
    if ('ecommerce' in event) {
      window['dataLayer']?.push({ ecommerce: null })
    }
    window['dataLayer']?.push({
      isB2B: isB2B,
      ...event,
    } as DataLayerPush & { isB2B: boolean })
  }
}

export function consent(grant?: ConsentLayerPush['consent']): void {
  if (isClient) {
    if (!grant) {
      return pushDataLayerEvent({
        event: 'consent',
        consent: [],
      } as ConsentLayerPush)
    }

    return pushDataLayerEvent({
      event: 'consent',
      consent: grant,
    } as ConsentLayerPush)
  }
}

export function cartTracking({ type, currencyCode, products }: CartMutation): void {
  switch (type) {
    case 'add':
      return pushDataLayerEvent({
        event: 'addToCart',
        ecommerce: {
          currencyCode,
          add: {
            products,
            actionField: {
              revenue: products.reduce((acc, product) => acc + Number(product.price), 0).toFixed(2),
            },
          },
        },
      } as AddToCartDataLayerPush)
    case 'remove':
      return pushDataLayerEvent({
        event: 'removeFromCart',
        ecommerce: {
          currencyCode,
          remove: {
            products,
            actionField: {
              revenue: products.reduce((acc, product) => acc + Number(product.price), 0).toFixed(2),
            },
          },
        },
      } as RemoveFromCartDataLayerPush)
  }
}

export function checkoutTracking({ currencyCode, products, step }: CheckoutMutation): void {
  pushDataLayerEvent({
    event: 'checkout',
    ecommerce: {
      currencyCode,
      checkout: {
        products,
        actionField: {
          step,
        },
      },
    },
  } as CheckoutDataLayerPush)
}

export function purchaseTracking({ currencyCode, products, actionField, customer }: PurchaseMutation): void {
  pushDataLayerEvent({
    event: 'purchase',
    ecommerce: {
      currencyCode,
      purchase: {
        products,
        actionField,
        customer,
      },
    },
  } as PurchaseDataLayerPush)
}

export function pageTracking({ products, list, currencyCode }: PageMutation): void {
  if (!products) {
    return pushDataLayerEvent({
      event: 'pageView',
    } as PageViewDataLayerPush)
  }

  const detail: DetailDataLayerPush['ecommerce']['detail'] = {
    products,
  }

  if (list) {
    detail.actionField = {
      list,
    }
  }

  pushDataLayerEvent({
    event: 'pageView',
    ecommerce: {
      currencyCode,
      detail,
    },
  } as PageViewDataLayerPush)
}

export function impressionTracking({ products, currencyCode, list }: ImpressionsMutation): void {
  return pushDataLayerEvent({
    event: 'impressions',
    ecommerce: {
      currencyCode,
      impressions: products?.map((props) => ({ ...props, list })),
    },
  } as ImpressionsDataLayerPush)
}

export function analyticsTracking(props: AnalyticsMutation): void {
  pushDataLayerEvent({ event: 'analytics', ...props } as AnalyticsDataLayerPush)
}
