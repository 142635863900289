'use client'

import { MouseEvent, MouseEventHandler, useState, useEffect } from 'react'

import { AnalyticsMutation } from 'core/types/tracking'
import mergeClasses from 'core/utils/mergeClasses'
import { analyticsTracking } from 'core/utils/tracking'

import Icon from 'components/Icon'
import PulseLoader from 'components/PulseLoader'
import RouteLink from 'components/RouteLink'

import type { colorType } from 'components/AdamTheme/theme/colors'
import type { IconType } from 'components/Icon'

type TrackingType = {
  action?: AnalyticsMutation['action']
} & Omit<AnalyticsMutation, 'action' | 'isB2B'>

type LinkProps = {
  to: string
  className?: string
  onClick?: MouseEventHandler<HTMLAnchorElement>
  target?: string
  style?: React.CSSProperties
  withoutCountry?: boolean
  children?: any
}

function Link({ to, ...props }: LinkProps): JSX.Element {
  const onMouseUp = ({ currentTarget }: MouseEvent<HTMLAnchorElement>): void => currentTarget && currentTarget.blur()

  return (
    <RouteLink
      href={to}
      onMouseUp={onMouseUp}
      {...props}
    />
  )
}

type ButtonProps = {
  id?: string
  type?: 'submit' | 'button'
  onClick?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => any | Promise<any>
  onMouseEnter?: () => void
  onMouseOver?: () => void
  onMouseLeave?: () => void
  styleType?: 'text' | 'button' | 'small-button' | 'link' | 'icon' | 'base'
  disabled?: boolean
  loading?: boolean
  inverted?: boolean
  color?: colorType
  className?: string
  prefetch?: boolean
  to?: string | null
  style?: any
  icon?: IconType | null
  customIcon?: any
  iconNotifications?: number | null
  iconFlat?: boolean
  children?: any
  target?: string
  tracking?: TrackingType | null
  noFocus?: boolean
  tabIndex?: number
}

export default function Button({
  type = 'button',
  styleType = 'button',
  disabled,
  inverted,
  color = 'ink',
  icon,
  customIcon,
  loading,
  iconNotifications,
  iconFlat,
  to,
  children,
  noFocus,
  tracking,
  onClick,
  ...props
}: ButtonProps): JSX.Element {
  const onMouseUp = ({ currentTarget }: MouseEvent<HTMLButtonElement | HTMLAnchorElement>): void =>
    currentTarget && currentTarget.blur()

  const [isLoading, setLoading] = useState(loading)
  const isButton = ['button', 'small-button'].includes(styleType)
  const loaderColor = isButton ? (inverted ? 'disabled' : 'ghost') : inverted ? 'ghost' : 'disabled'

  const pulseAmount = styleType === 'icon' ? 2 : 3

  const clickHandler = async (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>): Promise<void> => {
    const loadingOverride = typeof loading !== 'undefined'

    !loadingOverride && setLoading(true)
    onClick && (await onClick(event))

    if (tracking) {
      analyticsTracking({ action: 'click', ...tracking })
    }

    !loadingOverride && setLoading(false)
  }

  useEffect(() => {
    setLoading(loading)
  }, [loading])

  const iconColor = ['icon', 'text', 'link'].includes(styleType)
    ? inverted
      ? 'ghost'
      : disabled
        ? 'disabled'
        : color
    : inverted
      ? color
      : 'ghost'

  const content = (
    <>
      {!isLoading &&
        (customIcon
          ? customIcon
          : icon && (
              <Icon
                color={iconColor}
                name={icon}
                notifications={iconNotifications}
                flat={iconFlat}
                className="touch:group-active:[&>*]:fill-ghost"
              />
            ))}
      {children}
      {isLoading && (
        <PulseLoader
          amount={pulseAmount}
          color={loaderColor}
        />
      )}
    </>
  )

  let className = mergeClasses(
    `no-select transition items-center relative `,

    // show progress cursor while loading
    isLoading ? 'cursor-progress' : 'disabled:cursor-not-allowed'
  )

  switch (styleType) {
    case 'button':
    case 'small-button':
      className = mergeClasses(
        'text-lg font-bold inline-flex focus-xl leading-none justify-center whitespace-nowrap',

        //Decrease padding on the left if it is a icon
        !!icon ? `pl-sm pr-lg` : `px-lg`,
        inverted ? `bg-transparent hover:bg-transparent active:bg-transparent border ` : `border border-transparent `,

        //Disabled state for the text/background/borders
        isLoading || disabled
          ? inverted
            ? `text-transparent border-disabled duration-0`
            : `text-transparent bg-disabled duration-0`
          : inverted
            ? `text-${color} hover:text-${color}-hover active:text-${color}-active border-${color} hover:border-${color}-hover active:border-${color}-active`
            : `text-ghost bg-${color} hover:bg-${color}-hover active:bg-${color}-active`,

        //Disabled text color
        disabled && !loading && (inverted ? 'text-disabled' : 'text-ghost'),

        //Height for the small and normal button
        styleType === 'small-button' ? 'h-xl rounded-2xl' : 'h-button rounded-2xl',
        className
      )

      break

    case 'icon':
      className = mergeClasses(
        'inline-flex rounded-2xl w-xl h-xl text-hidden justify-center leading-none flex focus-2xl',
        `before:content-[''] before:block before:absolute before:inset-[-12px] before:opacity-0  before:bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] before:from-ink before:from-30% before:to-transparent before:to-70%`,
        !isLoading &&
          !disabled &&
          `group touch:active:duration-0 touch:active:before:opacity-100 touch:active:border-transparent`,

        //Disabled / loading state
        isLoading || disabled
          ? inverted && `bg-disabled`
          : inverted && `bg-${color} hover:bg-${color}-hover active:bg-${color}-active`,
        className
      )
      break

    case 'link':
      className = mergeClasses(
        'before:border-b before:w-[100%] before:flex before:border-ink before:bottom-[50%] before:opacity-0 before:absolute hover:before:opacity-100 before:transition before:translate-y-[18px] hover:before:translate-y-[14px]',
        className
      )
    case 'text':
      className = mergeClasses(
        'relative text-md w-fit inline-flex focus-sm lg:text-lg',
        isLoading && 'text-transparent',
        className
      )
      break

    case 'base':
      className = mergeClasses('flex', className)
      break
  }

  if (!!to && !disabled) {
    return (
      <Link
        to={to}
        tabIndex={noFocus ? -1 : 0}
        onClick={clickHandler}
        {...props}
        className={mergeClasses(className, props.className)}
      >
        {content}
      </Link>
    )
  }

  return (
    <button
      name={typeof children === 'string' ? children : ''}
      onMouseUp={onMouseUp}
      type={type}
      disabled={disabled || isLoading}
      tabIndex={noFocus ? -1 : 0}
      onClick={clickHandler}
      {...props}
      className={mergeClasses(className, props.className)}
    >
      {content}
    </button>
  )
}
