import mergeClasses from 'core/utils/mergeClasses'

import colors from 'components/AdamTheme/theme/colors'

export default function Loader({
  color = 'ghost',
  amount = 3,
  className,
}: {
  color?: keyof typeof colors
  amount?: number
  className?: string
}): React.ReactNode {
  return (
    <div className={mergeClasses('absolute top-1/2 left-1/2', className)}>
      <div className="relative flex -translate-x-1/2 -translate-y-1/2 ">
        {[...new Array(amount)].map((_, index) => (
          <div
            className={mergeClasses(
              'relative w-[6px] h-[6px] rounded-[6px] ',
              'not-first:ml-[6px]',
              `bg-${color}`,
              (amount > 2 && index !== 0 && index !== amount - 1) || (amount === 2 && index === 1)
                ? 'animate-pulse-large'
                : 'animate-pulse-small'
            )}
            key={index}
          />
        ))}
      </div>
    </div>
  )
}
