export { default as cart } from './cart.svg'
export { default as menu } from './menu.svg'
export { default as plus } from './plus.svg'
export { default as star } from './star.svg'
export { default as user } from './user.svg'
export { default as check } from './check.svg'
export { default as cross } from './cross.svg'
export { default as mail } from './mail.svg'
export { default as minus } from './minus.svg'
export { default as trash } from './trash.svg'
export { default as world } from './world.svg'
export { default as filter } from './filter.svg'
export { default as search } from './search.svg'
export { default as payment } from './payment.svg'
export { default as percent } from './percent.svg'
export { default as refresh } from './refresh.svg'
export { default as delivery } from './delivery.svg'
export { default as chevronUp } from './chevron-up.svg'
export { default as chevronDown } from './chevron-down.svg'
export { default as chevronLeft } from './chevron-left.svg'
export { default as chevronRight } from './chevron-right.svg'
export { default as gridSingle } from './grid-single.svg'
export { default as gridSingleActive } from './grid-single-active.svg'
export { default as gridMultiple } from './grid-multiple.svg'
export { default as gridMultipleActive } from './grid-multiple-active.svg'
export { default as information } from './information.svg'
export { default as logout } from './logout.svg'
export { default as quickBuy } from './quick-buy.svg'
export { default as sound } from './sound.svg'
export { default as mute } from './mute.svg'
export { default as edit } from './edit.svg'
export { default as external } from './external.svg'
export { default as measure } from './measure.svg'
export { default as map } from './map.svg'
export { default as instagram } from './instagram.svg'
export { default as facebook } from './facebook.svg'
export { default as spotify } from './spotify.svg'
export { default as image } from './image.svg'
export { default as imageError } from './image-error.svg'
