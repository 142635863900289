'use client'

import { SVGProps, ReactElement } from 'react'

import mergeClasses from 'core/utils/mergeClasses'

import colors from 'components/AdamTheme/theme/colors'
import sizes from 'components/AdamTheme/theme/sizes'

import * as iconList from './assets'
export type IconType = keyof typeof iconList

export default function Icon({
  name,
  color = 'ink',
  flat,
  notifications,
  small,
  className,
  ...props
}: {
  name: IconType
  flat?: boolean
  color?: keyof typeof colors
  notifications?: number | null
  small?: boolean
  className?: string
}): JSX.Element | null {
  const FilteredIcon = (iconList as Record<string, (props: SVGProps<SVGElement>) => ReactElement>)[name]
  if (!FilteredIcon) {
    return null
  }

  const size = small ? sizes.iconSmall : sizes.icon

  const classNameBase = mergeClasses(
    `inline-flex relative shrink justify-center items-center transition`,
    small ? 'w-lg h-lg' : 'w-xl h-xl',
    className
  )

  const colorClasses = mergeClasses(`[&>*]:fill-${color}`, `[&>*]:hover:fill-${color}-hover`)
  const notificationColor = flat ? `after:bg-${color}` : 'after:bg-grass'

  return (
    <>
      {notifications ? (
        <div
          className={mergeClasses(
            classNameBase,
            'leading-none',
            notifications.toString().length > 2 ? 'after:w-lg' : 'after:w-md',
            `relative after:pointer-events-none after:font-bold after:leading-none after:absolute after:right-[3px]  after:top-[1px] after:flex after:h-md after:animate-notification-intro after:items-center after:justify-center after:rounded-md after:text-xs after:text-ghost after:content-[attr(data-notifications)] `,

            notificationColor
          )}
          data-notifications={notifications}
        >
          <FilteredIcon
            viewBox={`0 0 ${sizes.icon} ${sizes.icon}`}
            width={size}
            height={size}
            className={colorClasses}
            {...props}
          />
        </div>
      ) : (
        <FilteredIcon
          viewBox={`0 0 ${sizes.icon} ${sizes.icon}`}
          width={size}
          height={size}
          className={mergeClasses(classNameBase, colorClasses)}
          {...props}
        />
      )}
    </>
  )
}
